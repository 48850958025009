<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex justify-content-between">
        <h1 class="my-auto">Liste des Établissements</h1>
        <b-button
          v-if="$can('create', 'etablissement')"
          class="mt-1 float-right"
          variant="primary"
          @click="$router.push({ name: 'etablissements-create' })"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Ajouter un établissement</span>
        </b-button>
      </div>
      <div v-if="true" class="my-2">
        <b-row>
          <!-- nom etab -->
          <b-col md="6">
            <b-form-group
              label-for="departement"
              label="Nom de l'établissement"
            >
              <b-form-input
                v-model="filtersForm.nomEtablissement"
                type="text"
                class="form-control"
                placeholder="Nom de l'établissement"
              />
            </b-form-group>
          </b-col>
          <!-- type_ets -->
          <b-col md="6">
            <b-form-group label="Type d'établissement" label-for="type_ets">
              <v-select
                v-model="filtersForm.typeEtablissement"
                :options="typesEts"
                label="nom"
                :clearable="true"
                placeholder="Choisir un type d'établissement"
                :reduce="(item) => item._id"
              />
            </b-form-group>
          </b-col>
          <!-- departement -->
          <b-col md="6">
            <b-form-group label-for="departement" label="Département">
              <v-select
                v-model="filtersForm.departement"
                placeholder="Choisir un département"
                :clearable="true"
                :options="departementsList"
              />
            </b-form-group>
          </b-col>
          <!-- commune -->
          <b-col md="6">
            <b-form-group label-for="commune" label="Commune">
              <v-select
                v-model="filtersForm.commune"
                placeholder="Choisir une commune"
                :clearable="true"
                :options="communesList"
              />
            </b-form-group>
          </b-col>
          <!-- Lieu de résidence habituelle ( Département) -->
          <!-- <b-col md="6">
            <b-form-group label="Département">
              <v-select
                v-model="filtersForm.departement"
                type="text"
                :options="departementsList"
                :clearable="true"
                label="name"
                placeholder="Département du lieu de résidence"
              />
            </b-form-group>
          </b-col> -->
          <!-- Lieu de résidence habituelle (Ville/Commune) -->
          <!-- <b-col md="6">
            <b-form-group label="Commune">
              <v-select
                v-model="filtersForm.commune"
                :reduce="(option) => option.name"
                type="text"
                :clearable="true"
                :options="communesList"
                label="name"
                placeholder="Commune du lieu de résidence"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="12">
            <DepartementCommuneArrondissementVillageForm
              :active-fields="['departement', 'commune']"
              :show-required-tags="{ departement: false, commune: false }"
              :break-points="departementFormProps.breakPoints"
              :provided-labels="departementFormProps.departementFormLabels"
              :can-update-child-on-parent-changes="
                departementFormProps.canUpdateChildOnParentChanges
              "
              @input="onDepartementFormChange"
            />
          </b-col> -->
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="d-flex flex-column">
              <b-form-checkbox
                checked="true"
                name="check-button"
                switch
                inline
                v-model="filterStyleAnd"
              >
                {{
                  filterStyleAnd
                    ? 'Filtrer avec un "ET" logique'
                    : 'Filtrer avec un "OU" logique'
                }}
              </b-form-checkbox>
              <p class="mt-1">
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="text-warning mr-1"
                />
                <small>{{
                  filterStyleAnd
                    ? 'Filtrer avec un "ET" logique  signifie que si vous choisissez plusieurs critères avec l\'option "ET",  tous les critères doivent être vrais en même temps pour qu\'un résultat s\'affiche'
                    : 'Filtrer avec un "OU" logique  signifie que si vous choisissez plusieurs critères avec l\'option "OU", si l\'un des critères est vrai, les résultats seront affichés.'
                }}</small>
              </p>
            </div>
            <b-button
              class="float-right"
              variant="primary"
              @click="getListEstablishments()"
            >
              <feather-icon icon="SearchIcon" class="mr-25" />
              <span>Rechercher</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- exportation -->
        <div class="row">
          <!-- down all -->
          <div class="col-lg-3 col-md-6 mt-1">
            <b-button
              class="float-right"
              variant="primary"
              block
              @click="uploadData('allData')"
            >
              <feather-icon icon="DownloadCloudIcon" class="mr-25" />
              <span>Télécharger toutes les données</span>
              <b-spinner v-if="loadingExport" small class="ml-1" />
            </b-button>
          </div>
          <div class="col-lg-3 col-md-1" />
          <!-- down selection -->
          <div class="col-lg-3 col-md-1" />
          <div
            v-if="tableData && tableData.length > 0"
            class="col-lg-3 col-md-4 mt-1"
          >
            <b-button
              class="float-right"
              variant="primary"
              block
              @click="uploadData('filteredData')"
            >
              <feather-icon icon="DownloadCloudIcon" class="mr-25" />
              <span>Télécharger les données filtrées</span>
              <b-spinner v-if="loadingExport" small class="ml-1" />
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card>
      <b-table
        responsive
        :items="filteredTableData"
        :fields="tableColumns"
        show-empty
        empty-text="Aucun resultats"
        :busy="isLoadingTableData"
        hover
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(id)="data">
          <strong>{{
            (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
            data.index +
            1
          }}</strong>
        </template>
        <template #cell(ref)="data">
          <b-avatar variant="dark"> <feather-icon icon="ImageIcon" /> </b-avatar
          >{{ "  " }}
          {{ data.item.ref }}
        </template>
        <!-- <template #cell(statut)="data">
          <b-badge v-if="data.item.isVerified" variant="success">
            Validé
          </b-badge>
          <b-badge v-if="!data.item.isVerified" variant="warning">
            En attente
          </b-badge>
          <b-badge v-if="data.item.isRejected" variant="danger">
            Rejetté
          </b-badge>
        </template> -->
        <template #cell(actions)="data">
          <!-- <b-button variant="primary" class="mx-0">
            <feather-icon icon="EyeIcon" />
            <span class="text-nowrap"> Détails</span>
          </b-button> -->
          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Détails"
            variant="primary"
          >
            <b-dropdown-item
              @click="
                $router.push({
                  name: 'etablissements-details',
                  params: {
                    etablissementId: data.item._id,
                  },
                })
              "
              >Afficher le détail / Modifier</b-dropdown-item
            >
            <b-dropdown-item @click="showConfirmeArchiveAgentModal"
              >Supprimer</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <div>
        <PaginationComponent
          :table-data="tableData"
          :per-page="paginationData.perPage"
          :total-rows="paginationData.total"
          @change="onPaginationChange"
        />
      </div>
    </b-card>
    <b-modal
      id="modal-1"
      ref="confirmeArchiveAgentModalRef"
      title="Alerte de suppression"
      ok-only
      ok-title=""
      ok-variant=""
    >
      <b-card-text>
        Êtes-vous sûre de vouloir supprimer le compte de cet utilisateur ?
        <!-- {{etablissementId}} {{adminEtablissementEmail}} -->
      </b-card-text>
      <template #modal-footer>
        <div class="">
          <b-button variant="danger" @click="archiverAgent">
            <b-spinner v-if="isarchivingAgent" small /> Supprimer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
  BCardText,
  BBadge,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import PaginationComponent from "@/components/PaginationComponent.vue";
import DepartementCommuneArrondissementVillageForm from "@/components/DepartementCommuneArrondissementVillageForm.vue";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import usersStroreModule from "@/store/users";
import Ripple from "vue-ripple-directive";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import utilsService from "@/services/utils/utils.service";
import { buildFiltersForRequest } from "@/helpers/http-utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ArticleList",
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BFormInput,
    BSpinner,
    BCard,
    BCardText,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    vSelect,
    PaginationComponent,
    DepartementCommuneArrondissementVillageForm,
  },
  directives: {
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "users", module: usersStroreModule },
      { path: "params", module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    const departementsList = utilsService.getDepartements();
    const communesList = utilsService.getCommunes();

    return { departementsList, communesList, requiredStoreModules };
  },
  data() {
    return {
      confirmeArchiveAgentModalRef: "confirmeArchiveAgentModal",

      isLoadingTableData: false,
      isarchivingAgent: false,
      departementFormProps: {
        canUpdateChildOnParentChanges: {
          departement: true,
          // commune: true,
        },
        departementFormLabels: {
          departement: "Département",
          commune: "Commune",
          // arrondissement: 'Arrondissement',
        },
        breakPoints: { cols: { lg: "12" } },
      },
      typesEts: [],

      filtersForm: {
        nomEtablissement: "",
        departement: "",
        commune: "",
        typeEtablissement: "",
      },
      filterStyleAnd: false,
      currentPage: 1,
      perPage: 10,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        { key: "id", label: "#", sortable: true },
        {
          key: "nomEtablissement",
          label: "Nom",
          sortable: true,
          thStyle: { width: "20%" },
        },
        {
          key: "departement",
          label: "Département",
          sortable: true,
        },
        {
          key: "commune",
          label: "Commune",
          sortable: true,
        },
        {
          key: "emailEtablissement",
          label: "Email",
          sortable: false,
          thStyle: { width: "20%" },
        },
        {
          key: "telephoneEtablissement",
          label: "Téléphone",
          sortable: false,
        },
        // {
        //   key: "statut",
        //   label: "statut inscription",
        //   sortable: false,
        // },

        "actions",
      ],
      isLoadingTableData: false,
      loadingExport: false,
    };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  computed: {
    ...mapGetters("params", {
      typesRoles: "getter_typesRoles",
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
        }
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        this.getListEstablishments();
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListEstablishments();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.getListEstablishments();
      },
    },
  },
  mounted() {
    this.getListTypesEtablissement();
  },

  methods: {
    // ...mapActions('etablissements', {
    // }),
    ...mapActions("etablissements", {
      // createEtablissement: 'createEtablissement',
      action_findEtablissement: "findEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
      action_exportEtablissements: "exportEtablissements",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("users", { archiveUser: "archiveUser" }),

    // -------------------------------------------------------------------

    archiverAgent() {
      this.isarchivingAgent = true;
      const data = {
        etablissementId: this.etablissementId,
        adminEtablissementEmail: this.adminEtablissementEmail,
      };
      console.log("------ data: -------- ", data, this.redirectRoute);

      // return

      this.rejectInscriptionNotification(data)
        .then((response) => {
          this.isarchivingAgent = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération réussie",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          if (this.redirectRoute) {
            this.$router.push({
              name: this.redirectRoute,
            });
          }

          this.hideConfirmeArchiveAgentModal();
        })
        .catch((error) => {
          this.isarchivingAgent = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          // console.log(error)
        });
    },
    showConfirmeArchiveAgentModal() {
      this.$refs["confirmeArchiveAgentModalRef"].show();
    },
    hideConfirmeArchiveAgentModal() {
      this.$refs["confirmeArchiveAgentModalRef"].hide();
    },
    onDepartementFormChange(val) {
      this.filtersForm.departement = val.departement;
      this.filtersForm.commune = val.commune;
    },
    onPaginationChange(val) {
      // console.log("paginationChange", val);
      this.paginationData = { ...this.paginationData, ...val };
    },
    getListTypesEtablissement() {
      this.action_getListTypesEtablissement()
        .then((res) => {
          this.typesEts = (res.data.resources || []).map((e) => {
            const { sousTypes } = e;
            return {
              ...e,
              sousTypes: [...sousTypes].map((soustype) => ({
                value: soustype,
                text: soustype,
                ...(soustype === "Autres" && {
                  requireManualInput: true,
                  input: "",
                  code: "autres_sous_types",
                }),
              })),
            };
          });
        })
        .catch((err) => {});
    },
    getListEstablishments() {
      this.isLoadingTableData = true;
      // const userRole = this.typesRoles.find(
      //   e => e.nom === 'Agent Etablissement',
      // )
      // console.log(userRole)
      // const userRoleId = userRole._id
      // console.log('this.filtersForm :>> ', this.filtersForm)
      const filters = buildFiltersForRequest(this.filtersForm);
      const reqOptions = {
        params: {
          filter: filters,
          page: this.paginationData.currentPage - 1,
          perPage: this.paginationData.perPage,
          filterStyleAnd: this.filterStyleAnd ? "$and" : "$or",
        },
      };
      console.log("reqOptions: ", reqOptions);
      this.action_findEtablissement(reqOptions)
        .then((response) => {
          console.log("response::: ", response);
          this.tableData = response.data.resources;
          this.paginationData.total = response.data.total;
          this.isLoadingTableData = false;

          if (this.paginationData.metaData.from == 0)
            this.paginationData.metaData.from += 1;
          else {
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage;
          }
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            this.tableData.length;
        })
        .catch((err) => {
          this.isLoadingTableData = false;
          // console.log('error-users-list', err)
        });

      // setTimeout(() => {
      //   this.tableData = new Array(200)
      //     .fill({
      //       nom: 'Nom',
      //       prenom: 'Prenom',
      //       email: 'user@user.com',
      //     })
      //     .map((e, i) => {
      //       const temp = JSON.parse(JSON.stringify(e))
      //       return { ...temp, id: i }
      //     })

      //   this.isLoadingTableData = false
      // }, 2000)
    },
    uploadData(action) {
      this.loadingExport = true;
      // if( action === 'allData'){
      //   this.query.filter.type = []
      //   this.query.filter.text = []
      // }
      let params = {};
      if (action === "filteredData") {
        const filters = buildFiltersForRequest(this.filtersForm);
        params = {
          filter: filters,
        };
      }
      // console.log("params: ", params);

      this.action_exportEtablissements({ params: { ...params } })
        .then(async (res) => {
          if (res.status === 200) {
            this.loadingExport = false;
            try {
              const blob = res.data;
              const newBlob = new Blob([blob]);
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement("a");
                link.href = objUrl;
                link.download = "inscriptions.csv";
                link.click();
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl);
                }, 250);
              }
            } catch (error) {
              console.log("error: ", error);
            }
          }
        })
        .catch((err) => {
          this.loadingExport = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
